<template>
  <YLayoutBodyMenu :menu="[
      {title:'分类管理',path:'/card/article/cate'},
      {title:'文章管理',path:'/card/article/page'},
  ]">
    <router-view/>
    </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";

export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>